import classnames from 'classnames';
import React from 'react';

import { EdgeShadowsOverlay } from '../../components/backdrops/EdgeShadowsOverlay';

type ScrollingMarqueeListProps<T> = {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
};
function ScrollingMarqueeList<T>(props: ScrollingMarqueeListProps<T>) {
  const { items, renderItem } = props;
  return (
    <EdgeShadowsOverlay className="flex" shadowHeight={200} shadowWidth={200}>
      <MarqueeListRenderer items={items} renderItem={renderItem} type="primary" />
      <MarqueeListRenderer items={items} renderItem={renderItem} type="follow" />
    </EdgeShadowsOverlay>
  );
}
ScrollingMarqueeList.displayName = 'ScrollingMarqueeList';
export default ScrollingMarqueeList;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

type MarqueeListRendererProps<T> = {
  items: T[];
  type: 'primary' | 'follow';
  renderItem: (item: T) => React.ReactNode;
};
function MarqueeListRenderer<T>(props: MarqueeListRendererProps<T>) {
  const { items, type, renderItem } = props;
  const conditionalClassNames = {
    'animate-marqueeToLeftFollow absolute top-0': type === 'follow',
    'animate-marqueeToLeft': type === 'primary',
  };
  return (
    <div className={classnames('py-4 whitespace-nowrap flex items-center', conditionalClassNames)}>
      {items.map((text, index) => (
        <React.Fragment key={index}>{renderItem(text)}</React.Fragment>
      ))}
    </div>
  );
}
MarqueeListRenderer.displayName = 'MarqueeListRenderer';
