import { isNumber } from '@gaspardesk/utils';
import React, { useCallback } from 'react';

import IntlCurrency from '../../components/formatters/IntlCurrency';
import FormInput from '../../components/forms/FormInput';

const CalculateROIWidget: React.FC = () => {
  const [costSavings, setCostSavings] = React.useState<number | null>(null);

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const employeesCount = event.target['employees-count-input'].value;
      const ticketsCount = event.target['tickets-count-input'].value;
      const value = calculateCostSavings({ numberOfEmployees: employeesCount, numberOfMonthlyTickets: ticketsCount });
      setCostSavings(value);
    },
    [setCostSavings],
  );

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center gap-10">
      <div className="max-w-2xl text-pureWhite">
        <h1 className="leading-none pb-6 md:pb-2">Calculate your ROI</h1>
        <p>
          {`Evaluate how well your investment will perform and estimate your money savings when using Gaspar AI. Return On Investment is
          calculated by dividing an investment's net profit (or loss) by its initial cost.`}
        </p>
      </div>
      <div className="text-center flex flex-col justify-items-center text-pureWhite">
        <form
          onSubmit={handleFormSubmit}
          className="grid grid-cols-12 gap-5 w-full max-w-[400px] sm:max-w-[600px] self-center p-4 md:p-6 bg-pureWhite/10 rounded-t-2xl"
        >
          <div className="col-span-12 text-left">
            <FormInput
              className="w-full"
              required={true}
              type="number"
              id="employees-count-input"
              label="Number of Employees"
              placeholder="Type here..."
            />
          </div>
          <div className="col-span-12 text-left">
            <FormInput
              className="w-full"
              required={true}
              type="number"
              id="tickets-count-input"
              label="Number of monthly tickets"
              placeholder="Type here..."
            />
          </div>
          <div>
            <button type="submit" className="border-1 text-pureWhite rounded-full px-4 py-2 text-l">
              Calculate
            </button>
          </div>
        </form>
        <div className="bg-pureWhite/20 p-10 rounded-b-2xl flex flex-col gap-2">
          <p>Your annual cost savings are:</p>
          <p className="text-4xl font-bold">
            {isNumber(costSavings) && <IntlCurrency animated={true} value={costSavings} decimalPlaces={0} />}
            {!isNumber(costSavings) && '- - -'}
          </p>
        </div>
      </div>
    </div>
  );
};
CalculateROIWidget.displayName = 'CalculateROIWidget';
export default CalculateROIWidget;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

export const calculateCostSavings = (params: { numberOfEmployees: number; numberOfMonthlyTickets: number }) => {
  const { numberOfEmployees, numberOfMonthlyTickets } = params;
  const costPerMonth = numberOfEmployees * 3;
  const moneySavedPerMonth = 25 * numberOfMonthlyTickets * 0.4;
  const costSavings = 12 * (moneySavedPerMonth - costPerMonth);
  return Math.max(costSavings, 0);
};
