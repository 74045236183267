import { def } from '@gaspardesk/utils';
import Image from 'next/image';
import React from 'react';

import { StrapiApiScrollingLogo } from '../../lib/integrations/strapi/types';
import ScrollingMarqueeList from './common';

type ScrollingLogosWidgetProps = {
  logos: StrapiApiScrollingLogo[];
};
const ScrollingLogosWidget: React.FC<ScrollingLogosWidgetProps> = React.memo((props) => {
  const { logos } = props;
  const renderItem = (item: StrapiApiScrollingLogo) => (
    <span className="flex  items-center align-middle w-[150px] select-none px-4">
      <Image src={item.attributes.url} width={150} height={72} alt={`${item.id}`} />
    </span>
  );
  return (
    <div className="mt-6">
      <p className="text-center text-darkGray">Trusted by</p>
      <div className="flex flex-col text-center">
        <ScrollingMarqueeList items={logos} renderItem={(item) => renderItem(item)} />
      </div>
    </div>
  );
});
ScrollingLogosWidget.displayName = 'ScrollingLogosWidget';
export default ScrollingLogosWidget;
