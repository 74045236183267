import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import React from 'react';

import { CDNAssets } from '../CDN';
import { ContactUsMode } from '../components/forms/ContactForm';
import AnimatedText from '../components/interactive/AnimatedText';
import ScrollParallax from '../components/interactive/ScrollParallax';
import AlternateLayoutList from '../components/layout/lists/AlternateLayoutList';
import HeaderSection, { HeaderSectionDestinationUrl } from '../components/layout/sections/HeaderSection';
import PageSection, { PageSectionType } from '../components/layout/sections/PageSection';
import { StrapiApiClient } from '../lib/integrations/strapi/StrapiApiClient';
import { StrapiApiPlugin, StrapiApiScrollingLogo } from '../lib/integrations/strapi/types';
import { withGlobalDefaultStaticProps } from '../lib/next-hooks/withGlobalDefaultStaticProps';
import { getPageSeoConfig } from '../lib/seo/pageSeoConfigs';
import pageRoutes from '../router/pageRoutes';
import { NextPageWithLayout } from '../types/global-types';
import ScrollingLogosWidget from '../widgets/animated/ScrollingLogosWidget';
import CalculateROIWidget from '../widgets/interactive/CalculateROIWidget';
import ContactUsWidget from '../widgets/interactive/ContactUsWidget';
import AllIntegrationsShowcase from '../widgets/showcases/AllIntegrationsShowcase';
import PlatformShowcase, { SegmentOption } from '../widgets/showcases/PlatformShowcase';

// Dynamically import with SSR disabled
const PricingShowcase = dynamic(() => import('../widgets/showcases/PricingShowcase'), { ssr: false });

type HomePageStaticProps = {
  plugins: StrapiApiPlugin[];
  scrollingLogos: StrapiApiScrollingLogo[];
};

export const getStaticProps: GetStaticProps<HomePageStaticProps> = async (context) => {
  const scrollingLogos = await StrapiApiClient.getAllScrollingLogos();
  const plugins = await StrapiApiClient.getAllPlugins();
  const props: HomePageStaticProps = { plugins, scrollingLogos };
  return withGlobalDefaultStaticProps(context, { props });
};

const HomePage: NextPageWithLayout<HomePageStaticProps> = (props) => {
  const { plugins, scrollingLogos } = props;
  const seoConfig = getPageSeoConfig(pageRoutes.Index);
  return (
    <>
      <NextSeo {...seoConfig} />
      <HeaderSection
        title={
          <div>
            <span className="mr-4">Experience the magic of AI</span>
            <AnimatedText terms={['support', 'automation', 'assistance', 'excellence']} animationDelayMS={4000} />
          </div>
        }
        description="AI employee helpdesk for smarter support. Boost engagement, amplify productivity with Generative AI."
        destinationUrl={HeaderSectionDestinationUrl.RequestDemo}
      />
      <PageSection isNarrow={true}>
        <ScrollingLogosWidget logos={scrollingLogos} />
      </PageSection>
      <PageSection type={PageSectionType.White}>
        <AlternateLayoutList
          items={[
            {
              imageUrl: CDNAssets.getUrl('home-page-reduce-manual-work'),
              orientation: 'left',
              title: 'Reduce manual work by 80%',
              description:
                "Automate repetitive tasks and workflows, answer questions in seconds, optimize knowledge management and auto-resolve 40% of routine issues. All within your company's chat platform.",
            },
            {
              imageUrl: CDNAssets.getUrl('home-page-gen-ai-support'),
              orientation: 'right',
              title: 'GenAI-powered enterprise support',
              description:
                'The perfect blend of Generative AI with human expertise. Streamline your operations across company with our ChatGPT-like Virtual Agent, Workflow Automation and Business Insights.',
            },
          ]}
        />
      </PageSection>
      <PageSection type={PageSectionType.BlueElectric}>
        <PlatformShowcase
          images={{
            [SegmentOption.Slack]: CDNAssets.getUrl('home-page-slack'),
            [SegmentOption.Teams]: CDNAssets.getUrl('home-page-teams'),
            [SegmentOption.JIRA]: CDNAssets.getUrl('home-page-jira'),
          }}
        />
      </PageSection>
      <PageSection>
        <ScrollParallax
          title="Powerful AI support capabilities"
          panels={[
            {
              title: 'Auto-resolve issues',
              description: 'Reset passwords, update groups and email lists, and so much more.',
              imageUrl: CDNAssets.getUrl('home-page-auto-resolve-issues'),
            },
            {
              title: 'Automate processes',
              description: 'Streamline onboarding, offboarding and other repetitive activities.',
              imageUrl: CDNAssets.getUrl('home-page-automate-processes'),
            },
            {
              title: 'Offer instant answers',
              description: 'Give accurate answers in seconds with no need to search.',
              imageUrl: CDNAssets.getUrl('home-page-offer-instant-answers'),
            },
            {
              title: 'Accelerate approvals',
              description: 'Automate and speed-up approval requests, such as asking for paid time off.',
              imageUrl: CDNAssets.getUrl('home-page-accelerate-approvals'),
            },
            {
              title: 'Optimise knowledge management',
              description: 'Fast-track the right content creation and fill knowledge gaps.',
              imageUrl: CDNAssets.getUrl('home-page-optimise-knowledge-management'),
            },
            {
              title: 'Automate ticketing',
              description: 'Fast-track the right content creation and fill knowledge gaps.',
              imageUrl: CDNAssets.getUrl('home-page-automate-ticketing'),
            },
          ]}
        />
      </PageSection>
      <PageSection>
        <AllIntegrationsShowcase plugins={plugins} />
      </PageSection>
      <PageSection type={PageSectionType.BlackToWhiteAnimated}>
        <PricingShowcase />
      </PageSection>
      <PageSection type={PageSectionType.PurpleDiagonal}>
        <CalculateROIWidget />
      </PageSection>
      <PageSection>
        <ContactUsWidget defaultMode={ContactUsMode.FreeTrial} showAllModes={true} />
      </PageSection>
    </>
  );
};
HomePage.displayName = 'HomePage';
export default HomePage;
