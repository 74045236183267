import classnames from 'classnames';
import Image from 'next/image';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMeasure } from 'react-use';

import { MOBILE_MENU_WIDGETS_WIDTH } from '../layout/constants';

const CARD_WIDTH = 500;
const CARD_WIDTH_MOBILE = 360;
const ROW_HEIGHT = CARD_WIDTH * 1.8;
const ROW_HEIGHT_MOBILE = CARD_WIDTH_MOBILE * 1.1;

type ScrollParallaxPanel = { title: string; description: string; imageUrl: string };

type ScrollParallaxProps = {
  title: string;
  panels: ScrollParallaxPanel[];
};
const ScrollParallax: React.FC<ScrollParallaxProps> = React.memo((props) => {
  const { title, panels } = props;
  const { ref: containerRef, inView } = useInView({ threshold: 0, triggerOnce: false });

  const [mesaureContainerRef, { width }] = useMeasure();

  const isMobileView = useMemo(() => width < MOBILE_MENU_WIDGETS_WIDTH, [width]);

  const panelBuckets = useMemo(
    () => (isMobileView ? panels.map((panel) => [panel]) : groupPanelsIntoBuckets(panels)),
    [panels, isMobileView],
  );

  const rowHeight = isMobileView ? ROW_HEIGHT_MOBILE : ROW_HEIGHT;
  const cardWidth = isMobileView ? CARD_WIDTH_MOBILE : CARD_WIDTH;

  return (
    <div ref={containerRef} className="relative text-center">
      <h1 className={classnames('z-0 drop-shadow-xl leading-none', { 'relative ': !inView, 'sticky top-[200px]': inView })}>{title}</h1>
      <div ref={mesaureContainerRef} className="relative z-10 mx-auto max-w-[1000px]">
        {panelBuckets.map((panelBucket, index) => {
          if (panelBucket.length === 1) {
            return (
              <ParallaxSingleCardRow key={`image-bucket-${index}`} cardWidth={cardWidth} rowHeight={rowHeight} panel={panelBucket[0]} />
            );
          }
          if (panelBucket.length === 2) {
            return <ParallaxMultiCardRow key={`image-bucket-${index}`} cardWidth={cardWidth} rowHeight={rowHeight} panels={panelBucket} />;
          }
          return null;
        })}
      </div>
    </div>
  );
});
ScrollParallax.displayName = 'ScrollParallax';
export default ScrollParallax;

type ParallaxSingleCardRowProps = {
  rowHeight: number;
  cardWidth: number;
  panel: ScrollParallaxPanel;
};
const ParallaxSingleCardRow: React.FC<ParallaxSingleCardRowProps> = React.memo((props) => {
  const { panel, rowHeight, cardWidth } = props;
  return (
    <div className="w-full flex flex-row items-center justify-center pt-[200px]" style={{ height: rowHeight }}>
      <ParallaxCard width={cardWidth} data={panel} />
    </div>
  );
});
ParallaxSingleCardRow.displayName = 'ParallaxSingleCardRow';

type ParallaxMultiCardRowProps = {
  rowHeight: number;
  cardWidth: number;
  panels: ScrollParallaxPanel[];
};
const ParallaxMultiCardRow: React.FC<ParallaxMultiCardRowProps> = React.memo((props) => {
  const { panels, rowHeight, cardWidth } = props;
  return (
    <div className="w-full flex flex-row items-center justify-between gap-[100px] pt-[200px]" style={{ height: rowHeight }}>
      <div style={{ transform: `translateY(-${rowHeight / 4}px)` }}>
        <ParallaxCard width={cardWidth} data={panels[0]} />
      </div>
      <div style={{ transform: `translateY(${rowHeight / 4}px)` }}>
        <ParallaxCard width={cardWidth} data={panels[1]} />
      </div>
    </div>
  );
});
ParallaxMultiCardRow.displayName = 'ParallaxMultiCardRow';

type ParallaxCardProps = {
  data: ScrollParallaxPanel;
  width: number;
};
const ParallaxCard: React.FC<ParallaxCardProps> = React.memo((props) => {
  const { width, data } = props;
  const { imageUrl, title, description } = data;
  return (
    <div
      className="flex flex-col items-center justify-between rounded-2xl bg-pureWhite/10 py-6 px-4 text-center backdrop-blur-md"
      style={{ width, height: width }}
    >
      <Image src={imageUrl} width={width / 2} height={width / 2} alt={description} className="" />
      <p className="text-2xl md:text-4xl font-bold">{title}</p>
      <p>{description}</p>
    </div>
  );
});
ParallaxCard.displayName = 'ParallaxCard';

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

type ParallaxImageBucket = [ScrollParallaxPanel] | [ScrollParallaxPanel, ScrollParallaxPanel];

// Create buckets of images to be displayed in each row.
// The allow sequence for images per row is 1, 2, 1, 2 etc
const groupPanelsIntoBuckets = (panels: ScrollParallaxPanel[]) => {
  const buckets: ParallaxImageBucket[] = [];
  const panelsQueue = [...panels];
  const extractFromQueue = (count: number) => {
    const bucket: ScrollParallaxPanel[] = [];
    for (let index = 0; index < count; index++) {
      panelsQueue.length > 0 && bucket.push(panelsQueue.shift());
    }
    return bucket;
  };
  let lastRowCount: number = 1;
  while (panelsQueue.length > 0) {
    buckets.push(extractFromQueue(lastRowCount) as ParallaxImageBucket);
    lastRowCount = lastRowCount === 1 ? 2 : 1;
  }
  return buckets;
};
