import classnames from 'classnames';
import React, { useEffect } from 'react';

type AnimatedTextProps = {
  terms: string[];
  animationDelayMS: number;
};
const AnimatedText: React.FC<AnimatedTextProps> = React.memo((props) => {
  const { animationDelayMS, terms } = props;
  const [activeTermIndex, setActiveTermIndex] = React.useState<number>(0);
  const [isAnimating, setIsAnimating] = React.useState<boolean>(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setActiveTermIndex((prev) => (prev + 1) % terms.length);
      setTimeout(() => {
        setIsAnimating(false);
      }, animationDelayMS - 100);
    }, animationDelayMS);
    return () => {
      clearInterval(interval);
    };
  }, [terms, animationDelayMS]);

  return <span className={classnames('inline-block', { 'animate-textMarquee': isAnimating })}>{terms[activeTermIndex]}</span>;
});
AnimatedText.displayName = 'AnimatedText';
export default AnimatedText;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/
