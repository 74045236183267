import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

const ANIMATION_DURATION_MS = 300;
const ANIMATION_STEPS = 10;

export const useAnimatedNumber = (targetValue: number, skipAnimation: boolean) => {
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(false);

  useInterval(
    () => {
      const stepValue = Math.floor(targetValue / ANIMATION_STEPS);
      const newValue = Math.min(currentValue + stepValue, targetValue);
      if (newValue === targetValue) {
        setIsAnimationActive(false);
        return;
      }
      setCurrentValue(currentValue + stepValue);
    },
    isAnimationActive ? ANIMATION_DURATION_MS / ANIMATION_STEPS : null,
  );

  useEffect(() => {
    setIsAnimationActive(!skipAnimation);
    setCurrentValue(0);
  }, [skipAnimation, targetValue]);
  return currentValue;
};
