import React from 'react';

import { useAnimatedNumber } from '../../hooks/useAnimatedNumber';

type IntlCurrencyProps = {
  value: number;
  decimalPlaces?: number;
  animated?: boolean;
};
const IntlCurrency: React.FunctionComponent<IntlCurrencyProps> = React.memo((props) => {
  const { value, decimalPlaces, animated } = props;
  const animatedValue = useAnimatedNumber(value, !animated);
  return <>{IntlCurrencyString(animatedValue, decimalPlaces)}</>;
});
IntlCurrency.displayName = 'IntlCurrency';
export default IntlCurrency;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

const IntlCurrencyString = (value: number, decimalPlaces = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
